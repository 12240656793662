import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './App.css';
import { useMediaQuery } from 'react-responsive';
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 927 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 927 })
  return isMobile ? children : null
}


var parse = require('html-react-parser');
const StoryblokClient = require('storyblok-js-client');
// const RichTextResolver = require('storyblok-js-client/dist/richTextResolver')
let Storyblok = new StoryblokClient({
  accessToken: '2GVRmHLGWQHYVu3leTzYPQtt',
  cache: {
    clear: 'auto',
    type: 'memory'
  }
});

// Storyblok.richTextResolver =  new RichTextResolver();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      types: [],
      selected_type: '',
      selected_project: '',
      selected_image: '',
      selected_gallery: [],
      gal_index: -1,
      about_content: '',
      about_bg: '',
      home_vid: '',
      project_vid: '',
      file_downloads: [],
      rhett_pic: '',
      home_page_open: true,
      is_desktop: window.matchMedia("(min-width: 927px)").matches
    };

    this.loadHomePage();
    this.loadTypes();
    this.loadProjects();

    window.history.replaceState({ // initial history state
      selected_project: '',
      selected_type: '',
      selected_image: '',
      selected_gallery: [],
      gal_index: -1,
      home_page_open: true
    },"","/");

    window.onpopstate = function(event) {
      this.setState(event.state);
    }.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    document.body.classList.remove('home-open');
    if (this.state.home_page_open) {
      document.body.classList.add('home-open');
    }
  }

  componentDidMount() {
    const handler = e => this.setState({is_desktop: e.matches});
    window.matchMedia("(min-width: 927px)").addListener(handler);
  }

  loadTypes() {
    Storyblok
      .get('cdn/stories', {
        starts_with: "project_types",
      })
      .then((response) => {
        this.setState({types: response.data.stories.map((t) => { return {[t.content.type]: [t.name,t.uuid]} })});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadProjects() {
    Storyblok
      .get('cdn/stories', {
        starts_with: "projects",
        resolve_relations: 'featured.gallery_images,gallery.gallery_images,project_type'
      })
      .then((response) => {
        this.setState({projects: response.data.stories});
        console.log(response.data.stories);
      })
      .catch((error) => {
        console.log(error);
      });
      // Storyblok.richTextResolver.render(blok.richtext)
  }

  loadHomePage() {
    Storyblok
      .get('cdn/stories', {
        starts_with: "home-page-content"
      })
      .then((response) => {
        // console.log('home page response',response);
        this.setState({
          about_content: response.data.stories[0].content.about_content,
          about_bg: response.data.stories[0].content.about_bg,
          home_vid: response.data.stories[0].content.home_video.url,
          rhett_pic: response.data.stories[0].content.rhett_pic,
          file_downloads: response.data.stories[0].content.file_downloads
        });
      })
      .catch((error) => {
        console.log(error);
      });
      // Storyblok.richTextResolver.render(blok.richtext)
  }

  renderNav() {
    if (this.state.types == null) {
      return <li key="loading">Loading...</li>
    }

    return this.state.types.map((type) => {
      return (
        <li key={Object.keys(type)[0]} className={this.state.selected_type===Object.keys(type)[0]?'selected':''} onClick={(e) => {/*document.body.classList.remove('home-open');*/ window.history.pushState({selected_project: '',selected_type: Object.keys(type)[0],selected_image: '',selected_gallery: [],gal_index: -1,home_page_open: false},"","/"); this.selectType(Object.keys(type)[0]); }}>{Object.values(type)[0][0]}</li>
      );
    });
  }

  selectType(t) {
    console.log('select type: ',t);
    window.scrollTo(0, 0);
    this.setState({
      home_page_open: false,
      selected_type: t,
      selected_project: '',
      selected_image: ''
    },
    function() {
      console.log("this.state: ",this.state);
    });

  }

  selectProject(i) {
    let proj = this.state.projects[i];
    // document.body.classList.remove('home-open');
    window.top.scrollTo(0, 0);
    this.setState({selected_project: i, gal_index: -1, home_page_open: false});
    window.history.pushState({selected_project: i, gal_index: -1}, '' ,"/" + proj.slug);
  }

  renderProjects() {
    if (this.state.projects == null) {
      return <div className="loading">Loading...</div>
    }

    // if (this.state.selected_type !== '') {
      
    // }

    return this.state.projects.filter((p) => {
      return this.state.selected_type==='' ? true : (0<p.content.project_type.filter((pt)=>{
        return this.state.selected_type===pt.content.type;
      }).length);
    }).map((project, index) => {
      // console.log('[project, index]',[project,index]);
      let i = this.state.projects.findIndex(e => e.id === project.id);
      return (<>
        <Mobile>
          <div className="project-preview" onClick={(e) => {this.selectProject(i);}} style={{backgroundImage: "url('"+this.transformImage(project.content.featured[0].asset,'fit-in/350x350/filters:format(png):quality(90)')+"')"}} key={project.id}>
            <div className="project-name">{project.content.title}</div>
          </div>
        </Mobile>
        <Desktop>
          <div className="project-preview" onClick={(e) => {this.selectProject(i);}} style={{backgroundImage: "url('"+this.transformImage(project.content.featured[0].asset,'fit-in/500x500/filters:format(png):quality(90)')+"')"}} key={project.id}>
            <div className="project-name">{project.content.title}</div>
          </div>
        </Desktop>
        </>
        
      );
    });
  }

  renderGallery() {
    let i = this.state.selected_project;
    let proj = this.state.projects[i];
    let gal = proj.content.gallery;
    let s_type = this.state.selected_type;
    if (i === '' || gal.length===0) {
      return <></>
    }
    
    let matched_type = this.state.types.filter((t) => {
        return Object.keys(t)[0] === s_type;
      })[0] || '';
    let selected_type_uuid = (matched_type !== '' ? Object.values(matched_type)[0][1] : '');

    return gal.map((img, index) => {
      let img_type_is_selected = img.project_type.filter((pt) => {
        return pt === selected_type_uuid;
      }).length>0;
      
      let asset_ext = this.getAssetExt(img);
      let asset_path = this.getAssetPath(img);
      var is_video = false;
      var is_link = false;


      if (asset_ext) {
        is_video = (asset_ext.includes('mp4') || asset_ext.includes('mov') || asset_ext.includes('avi')  || asset_ext.includes('webm') ? true : false);
        is_link = false;
      } else {
        is_video = false;
        is_link = true;
      }
      

      return (
        <div key={img._uid} onClick={(e) => { this.setState({ selected_image: img, gal_index: index }); }} className={"gallery-image " + ( img_type_is_selected ? 'highlight ' : ' ') + ( s_type !== '' ? 'filtered' : '')} style={{backgroundImage: 'url("'+this.transformImage((typeof img.asset !== 'undefined' ?  img.asset : img.thumbnail),'fit-in/500x500/filters:format(png):quality(85)')+'")'}}>
          {function(i_v) {
            if (is_video) {
             return <i className="fas fa-play play-icon"></i>;
            } else if(is_link) {
              return <a href={asset_path} className="external-url" target="_blank"><i className="fas fa-play play-icon"></i></a>;
            }
          }(is_video)}
        </div>
      );
    });
  }

  handleKeyPress = (event) => {
    let i = this.state.selected_project;
    let proj = this.state.projects[i];
    let gal = proj.content.gallery;
    let g = this.state.gal_index;

    if(event.key === 'ArrowRight'){
      // console.log('right!');
      if (g >= gal.length-1) {
        this.setState({ gal_index: -1 });
      } else {
        this.setState({ gal_index: g + 1 });
      }
    }

    if(event.key === 'ArrowLeft'){
      if (g === -1) {
        this.setState({ gal_index: gal.length - 1 });
      } else {
        this.setState({ gal_index: g - 1 });
      }
    }
  }

  getAssetExt(img) {
    var asset_path = [];
    var asset_ext = '';
    if (typeof img !== 'undefined') {
      // console.log(img);
      asset_path = (typeof img.asset !== 'undefined'? img.asset.split('.') : img.vimeo_url.url.split('.') );
      asset_ext = asset_path[asset_path.length - 1];
      if (asset_ext.includes('/')) { // is it external url?
        return false;
      } else {
        return asset_ext;
      }
    } else {
      return false;
    }
  }

  getAssetPath(img) {
    return (typeof img.asset !== 'undefined'? img.asset : img.vimeo_url.url );
  }

  swipeActions() {
    let i = this.state.selected_project;
    let proj = this.state.projects[i];
    let gal = typeof proj != 'undefined'?proj.content.gallery:[];
    let gi = this.state.gal_index;
    let self = this;

    console.log('attachSwipeGestures() ', document.getElementById('project-box'));
    if (document.getElementById('project-box') !== null) {
      let pDetails = document.getElementById('project-box');
      console.log('project detail objects: ', pDetails);
      var ht = new window.Hammer(pDetails);
      
      ht.on('swipe', function(e) {
        console.log('swipe event object', e);
        document.getElementById('project-box').style.left = (document.getElementById('project-box').style.left + e.deltaX) + "px";
        console.log("document.getElementById('project-box').style.left", document.getElementById('project-box').style.left)
        if (e.offsetDirection === 2) { // swiped left
          if (gi >= gal.length-1) {
            gi = -1;
          } else {
            gi = gi + 1;
          }
        }
        if (e.offsetDirection === 4) { // swiped right
          if (gi === -1) {
            gi = -1;
          } else {
            gi = gi + 1;
          }
        }
        self.setState({gal_index: gi});
        document.getElementById('project-box').style.left = "0px";
        console.log('gi: ',gi);
      });
      ht.get('swipe').set({ direction: window.Hammer.DIRECTION_HORIZONTAL });
    }
  }

  attachSwipeGestures() {
    let self = this;
    setTimeout(function() {
      self.swipeActions();
    }, 1000);
  }

  renderSelectedProject() {
    if (this.state.selected_project==='') {
      return <></>
    }
    
    // let zt = new ZingTouch.Region(document.getElementsByClassName('project-details'));
    let i = this.state.selected_project;
    let proj = this.state.projects[i];
    let gal = proj.content.gallery;
    let g = this.state.gal_index;
    let f_image_url = proj.content.featured[0].asset;
    let title = proj.content.title;
    let description = proj.content.description;
    let s_img = gal[g];
    let bg_pos = '';
    let bg_size = 'cover';
    let bg_color = (g >= 0 && typeof s_img.background_color !== 'undefined' ? s_img.background_color.color : false )
                  || (typeof this.state.projects[i].content.featured[0].background_color === 'undefined' ? '#000000' : this.state.projects[i].content.featured[0].background_color.color);
    var asset_path = [];
    var asset_ext = '';
    var bg;
    if (typeof s_img !== 'undefined') {
      asset_path = (typeof s_img.asset !== 'undefined'? s_img.asset.split('.') : s_img.vimeo_url.url.split('.') );
      asset_ext = asset_path[asset_path.length - 1];
    }

    if (asset_ext === 'webm' || asset_ext === 'mp4') {
      bg = '';
    } else {
      bg = 'url("'+(g >= 0 ? this.transformImage((typeof s_img.asset !== 'undefined' ? s_img.asset : s_img.thumbnail )) : this.transformImage(f_image_url))+'")'; // used to be 1600px
    }

    switch((typeof s_img !== 'undefined' && s_img.image_placement) || this.state.projects[i].content.featured[0].image_placement) {
      case 'full_top':
        bg_pos = 'top center';
        bg_size = 'cover';
        break;
      case 'full_center':
        bg_pos = 'center center';
        bg_size = 'cover';
        break;
      case 'full_bottom':
        bg_pos = 'bottom center';
        bg_size = 'cover';
        break;
      case 'contain':
        bg_pos = 'center center';
        bg_size = 'contain';
        break;
      default: 

    }
    
    return (
      
      <section id="project-box" className="project-details" style={{backgroundColor: bg_color, backgroundPosition: bg_pos, backgroundSize: bg_size, backgroundImage: bg }}>
        <div className="swipe-gesture"></div>
        {(typeof s_img !== 'undefined' && (asset_ext.includes('webm') || asset_ext.includes('mp4'))) ?
          <ReactPlayer className="video-player" url={(typeof s_img.asset !== 'undefined'? s_img.asset : s_img.vimeo_url.url )} preload="auto" controls playing playsinline={true} width="100%" height="100%" volume={80} muted={false} loop={true} />
          : ''}
        <div className="project-info">
        {(typeof s_img === 'undefined' ?<h2>{title}</h2> : <h2 style={{opacity: 0}}>{title}</h2>)}
          <article>
              {(typeof s_img === 'undefined' && description!=='' ? parse("<p><span>" + Storyblok.richTextResolver.render(description) + '</span></p>') : '')}
              {(typeof s_img !== 'undefined' ? parse('<p><span>' + s_img.description + '</span></p>') : '')}
          </article>
        </div>
        <section className="project-gallery">
          {this.renderGallery()}
        </section>
        <div className="keyboard-instructions">
        <div className="keyboard-row">
          <div className="key"></div>
          <div className="key dim">&#57689;</div>
          <div className="key"></div>
        </div>
        <div className="keyboard-row">
          <div className="key">&#57691;</div>
          <div className="key dim">&#57690;</div>
          <div className="key">&#57692;</div>
        </div>
        <div className="key-nav-instructions">
          <span>Use</span><span>Arrow</span><span>Keys</span>
          </div>
      </div>
      </section>
    );
  }

  renderHomePage() {
    let home_vid = this.state.home_vid;
    let about_content = this.state.about_content;
    let rhett_pic = this.state.rhett_pic;

    return (
      <>
      <section className="home-screen">
          <div
            className="home-video-player"
            dangerouslySetInnerHTML={{
              __html: `
              <video
              preload="metadata"
              loop
              muted
              autoplay
              playsinline
              width="100%"
              height="100%"
              onload="function(){this.play()}"
              >
                <source src=`+home_vid+` type="video/mp4" />
              </video>`
            }}
          />
          {/* <ReactPlayer className="home-video-player" url={home_vid} style={{ objectFit: 'cover' }} preload="auto" autoplay={true} playing={true} playsinline={true} controls={false} width="100%" height="100%" volume={0} muted={true} loop={true} /> */}
        </section>
      <section className="about-screen" id="about-screen" style={{/*backgroundImage: `url(${about_bg})`,*/ backgroundColor: 'transparent', backgroundSize: 'cover' }}>
        <div className="about-info">
          <h2>About Rhett</h2>
          <article>
              {(about_content!=='' ? parse("<p><span>" + Storyblok.richTextResolver.render(about_content) + '</span></p>') : '')}
          </article>
          <section className="about-files">
          <ul>{this.renderFileDownloads()}</ul>
        </section>
        </div>
        
        
        <section className="rhett-pic" style={{backgroundImage: `url(${rhett_pic})`}}></section>
      </section>
      </>
    );
  }

  renderFileDownloads() {
    let files = this.state.file_downloads;
    if (files.length===0) {
      return <></>
    }

    return files.map((file, index) => {
      return (
        <li key={file._uid}><a href={file.filename} target="_blank" rel="noopener">{file.name}</a></li>
      );
    });
  }

  transformImage(image, option="") {
    // console.log('transformImage() image ',image);
    // console.log('transformImage() option ',option);
    if (option === "") {
      if (this.state.is_desktop) {
        option = 'fit-in/1600x1600/filters:format(png):quality(85)';
      } else {
        option = 'fit-in/800x800/filters:format(png):quality(85)';
      }
    }
    
    
    var imageService = '//img2.storyblok.com/';
    var path = image.replace('//a.storyblok.com', '');
    // console.log('image: ',imageService + option + path)
    return imageService + option + path;
    // return image;
  }

  render() {
    return (
      <>
      <div className="rotate-phone">
        <p>Rotate<br /><i className="fas fa-sync-alt"></i><br />Phone</p></div>
      <header>
        {/* <h1><span className="rhett">Rhett</span> <span className="larue">LaRue</span></h1> */}
      </header>
      <nav>
      <div className="nav-button" onClick={() => {document.body.classList.toggle('nav-open');}}><i className="fas fa-bars"></i></div>
        <ul onClick={() => {document.body.classList.remove('nav-open');}}>
          { this.state.selected_project !== '' ? (
            <>
          <li key="home" className="home-link" onClick={() => {window.history.back();}}>&#8617;</li>
          </>
          ) : ''}
          <li key="home" className="home-link" onClick={() => {this.setState({selected_project: '', selected_image: '', home_page_open: true})}}><i className="fas fa-home"></i></li>
          <li key="about" className="about-link" onClick={() => {this.setState({selected_project: '', selected_image: '', home_page_open: true}); document.getElementById('about-screen').scrollIntoView();}}>&#57650;</li>
          <li key="all" className={this.state.selected_type===''?'selected':''} onClick={(e) => {window.history.pushState({selected_project: '',selected_type: '',selected_image: '',selected_gallery: [],gal_index: -1,home_page_open: false},"","/"); this.setState({home_page_open: false}); this.selectType('')}}>All</li>
          {this.renderNav()}
        </ul>
      </nav>
      <main onKeyDown={this.handleKeyPress} tabIndex="0">
        {this.renderHomePage()}
        <section className="project-grid">
          {this.renderProjects()}
        </section>
        {this.renderSelectedProject()}
        {this.attachSwipeGestures()}
      </main>
      <footer>
        <address><a href="mailto:rhett.larue@gmail.com">rhett.larue@gmail.com</a></address>
        <div className="footer-title"><span className="rhett">Rhett</span> <span className="larue">LaRue</span></div>
      </footer>
      </>
    );
  }
}

export default App;
